import Kuva from './sahkopostiKuva';
import './App.css';

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <p className='kapeammaksi-kuinRuutu-eka'>
        Hyyppä on kylä, tämä on Hyypän kylän www-sivu. 
        </p>
        <p className='kapeammaksi-kuinRuutu'>
          Kutsun yhdistyksiä, yrityksiä ja pienten kyläyhteisöjen ihmisiä eripuolilta Hyyppää 
          tekemään tästä sivustosta kaikkien yhteisen tiedotuskanavan joka palvelee kaikkia 
          tasapuolisesti, antamalla mahdollisuuden lisätä yllämainittujen tahojen tuottamat sisällöt 
          integroidusti osaksi yhtä sivustoa niin että kaikki mitä Hyypällä on tarjota löytyy helposti. 
        </p>
        <p className='kapeammaksi-kuinRuutu'>
          Miten on haluatko olla mukana?
        </p>
        <p className='kapeammaksi-kuinRuutu-vika'>
          Lisätietoja:
        </p>
        <Kuva />
        <footer>
        Terveisin: Jarkko Yli-Heikkuri :)
        </footer>
      </header>
    </div>
  );
}

export default App;
