import kuva from './images/sahkoposti.png';
import './sahkopostiKuva.css';

function Kuva() {
    return (
      
         <img className='kuvanLoota' src={kuva} alt="kuva sähköposti osotteesta tälle tyypille yli-heikkuri.fi" />
      
    );
}

export default Kuva;

